import { useEffect } from "react";
import api from "../Api";

function Example() {
    useEffect(() => {
        api.get("/").then((response) => {
            if(response.status === 200){
                console.log("✅ Backend connected successfully" )
            } else{
                console.error("❌ Could not GET / from backend")
            }
        })
    })

    return (
        <>
            <div className="font-extrabold text-3xl italic text-center w-full mt-12">
                Nice!
            </div>
            <div className="mt-2">
                Your <a href="https://swizzle.co" className="text-indigo-700 hover:text-indigo-500 font-bold">Swizzle</a> app is live
            </div>
        </>
    )
}

export default Example;