import React from 'react';
import { Route as SwizzleRoute, Routes as SwizzleRoutes } from 'react-router-dom';
import SwizzleHomePage from './pages/SwizzleHomePage';
import SwizzlePrivateRoute from './SwizzlePrivateRoute';
import Home from './pages/Home';

const RouteList = () => {
  return (
    <SwizzleRoutes>
  <SwizzleRoute path="/" element={<SwizzleHomePage />} />
  <SwizzleRoute path="/home" element={<Home />} />
</SwizzleRoutes>
  )
};

export default RouteList;
