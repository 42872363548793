import React from 'react';
import api from '../Api'; //Use this to make API calls (e.g. await api.get("/endpoint"))

const Home = () => {

    return (
        <div>
            {/* Your content here */}
        </div>
    );
};

export default Home;