import Example from "../components/Example";

//SwizzleHomePage is the home page (/) of your webapp
function SwizzleHomePage() {
    return (
      <div className="bg-gray-100 h-screen flex flex-col items-center">
        <Example />
      </div>
    );
  }
  
export default SwizzleHomePage;
  