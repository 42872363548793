import React from "react";
import { AuthProvider } from 'react-auth-kit';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import RouteList from './RouteList';

function App() {
  return (
    <AuthProvider authType={'localstorage'} authName={'_auth'}>
      <Router>
        <RouteList />
      </Router>
    </AuthProvider>
  );
}

export default App;
