import axios from 'axios';

// Usage:
// const response = await api.get('/endpoint');
const api = axios.create({
  baseURL: 'https://api.' + window.location.hostname,
});

//Set the auth token for any request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('_auth');
    const authHeader = token ? `Bearer ${token}` : null;
    
    if (authHeader) {
      config.headers['Authorization'] = authHeader;
    } else {
      delete config.headers['Authorization'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;